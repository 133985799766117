import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))
export const chargePatientCostShare = patient => api({
  method: 'billing.billing.api.cost_share.actions.sales_invoice_item.get_patient ',
  args: { patient },
  freeze: false,
})
export const attachChargeSheetToBill = patient => api({
  method: 'billing.billing.api.scripts.invoices_fix.attach_unbilled_sheets_to_patient_bill ',
  args: { patient },
  freeze: false,
})
