import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const generateGatePass = ({ patient }) => api({
  method: 'clinical.api.gate_pass_logic.generate_gate_pass_from_user_action_based_on_charge_sheet_balances',
  args: {
    patient,
  },
})

export const chargeSheetToInvoice = ({
  patient_number,
}) => api({
  method: 'clinical.api.patients.bulk_generate_inv_thread_three_in_one_single_patient',
  args: {
    patient_number,
  },
})

export const getPatientGatePasses = ({
  patient_number,
}) => api({
  method: 'clinical.api.patient_accounts.get_patient_gate_passes',
  args: {
    patient_number,
  },
})
