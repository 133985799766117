<template>
  <b-container>
    <b-row> {{ parent }} - <span> Billed by: </span> <b> {{ owner }}</b>
      <b-col />
      <b-button
        size="sm"
        class="ml-2"
        variant="primary"
        @click="selectAllRows"
      >
        Select all
      </b-button>
      <b-button
        size="sm"
        class="mx-2"
        variant="primary"
        @click="clearSelected"
      >
        Clear selected
      </b-button>
      <b-button
        v-if="selected.length"
        size="sm"
        variant="danger"
      >
        Post adjustment
      </b-button>
    </b-row>
    <b-row>
      <b-table
        ref="selectableTable"
        small
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        :items="items"
        :empty-text="`No items `"
        :fields="fields"
        :empty-filtered-text="`No items`"
        head-row-variant="secondary"
        :show-empty="true"
        selected-variant="warning"
        :select-mode="'multi'"
        selectable
        @row-selected="onRowSelected"
      >

        <template #cell(qty)="data">
          <b-form-input
            v-if="(selected.find(element => element == data.item))"
            v-model="selected[getIndex(data.item.id)].qtyx"
            style="width: 52px !important;"
          />
          <span v-else>{{ items[data.index].qty }}</span>
        </template>

        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="primary"
            @click="returnAll(data.item)"
          >
            Return all
          </b-button>
        </template>
      </b-table>
    </b-row>

    <h5 class="my-2">
      Returns:
    </h5>
    <b-row>
      <b-table
        ref="selectableTable2"
        small
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        :items="returns"
        :empty-text="`No items `"
        :fields="fields"
        :empty-filtered-text="`No items`"
        head-row-variant="secondary"
        :show-empty="true"
        selected-variant="warning"
        :select-mode="'multi'"
        selectable
      >
        <template #cell(item_code)="data">
          <ItemName :item_code="data.item.item_" />
        </template>

        <template #cell(quantity)="data">
          <b-form-input
            v-if="(selected.find(element => element == data.item))"
            v-model="selected[getIndex(data.item.id)].qtyx"
            style="width: 52px !important;"
          />
          <span v-else>{{ items[data.index].qty }}</span>
        </template>

        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="danger"
            @click="cancelReturn(data.item.name)"
          >
            Cancel Return
          </b-button>
        </template>
      </b-table>
    </b-row>

  </b-container>
</template>
<script>
import {
  chargeSheetAddItemReturn,
  chargeSheetCancelReturn,
} from '../chargesheet/service'
import ItemName from '../chargesheet/Item.vue'

export default {
  components: {
    ItemName,
  },
  props: {
    parent: String,
    owner: String,
    pateint: String,
  },
  data() {
    return {
      items: [],
      returns: [],
      result: null,
      warehouse: null,
      fields: ['id', 'item_name', 'quantity', 'price', 'is_processed', 'actions'],
      selected: [],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    migrate() {
      const payload = {}
      payload.source_charge_sheet = this.parent
    },

    postAdjustment() {
      if (this.getUserSession().user !== this.owner) {
        this.$bvToast.toast(' You are not the owner of this document. Ask the owner to post adjustment', {
          title: 'Owner Restriction',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }
      const results = this.selected.filter(element => element.qty !== element.qtyx)

      if (!results.length) {
        this.$bvToast.toast('No quantities adjustment have been made. We are doing nothing.', {
          title: 'No Change',
          variant: 'warning',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        this.clearSelected()
        return
      }

      let err = results.find(element => element.qtyx > element.qty)
      if (err) {
        this.$bvToast.toast(`In line ${err.id} Adjusted quantity exceed the pivot quantity. We are doing nothing.`, {
          title: 'Fatal Error',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }

      err = results.find(element => element.qtyx < 0)
      if (err) {
        this.$bvToast.toast(`Error In line ${err.id} Negative values not allowed here. We are doing nothing.`, {
          title: 'Fatal Error',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }

      this.$bvModal.msgBoxConfirm('This will post a negative deduction in the invoice and the stocks will be adjusted upwards.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes, go ahead',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const adjustedItems = results.map(item => {
              const current = {}
              current.rate = item.rate
              current.item_code = item.item_code
              current.qty = item.qty - item.qtyx
              return current
            })
            this.hitReturnApi(adjustedItems)
          }
        })
        .catch(err => {
          // An error occurred
        })
    },

    getIndex(id) {
      let currentIndex = null
      this.selected.forEach((element, index) => {
        if (element.id == id) {
          currentIndex = index
        }
      })
      return currentIndex
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    load() {
      const fields = ['name', 'item', 'item_name', 'quantity', 'price', 'is_processed', 'parentfield', 'patient']
      this.docGetList('Cost Share Item', { filters: { parent: this.parent }, fields, order_by: 'creation desc' }).then(data => {
        this.items = data.filter(item => item.parentfield == 'cost_share_items')
        this.items = this.items.map((item, index) => {
          item.id = index + 1
          return item
        })
        this.returns = data.filter(item => item.parentfield == 'returns' && item.patient == this.patient)
        this.returns = this.returns.map((item, index) => {
          item.id = index + 1
          return item
        })
      })
    },
    returnAll(charge_sheet_item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm  you want to return', {
          title: 'Confirm Return',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes, proceed',
          cancelTitle: 'Close',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            let total = 0
            this.returns.forEach(element => {
              if (element.item_code = charge_sheet_item.item_code) {
                total += element.qty
              }
            })

            if (total > 0) {
              this.$bvToast.toast('In line  Adjusted quantity exceed the pivot quantity. We are doing nothing.', {
                title: 'Exceeded Quantities',
                variant: 'danger',
                autoHideDelay: 5000,
                append: true,
                solid: true,
              })
              return
            }

            this.hitReturnApi([charge_sheet_item])
          }
        })
    },
    cancelReturn(chargeSheetItem) {
      this.$bvModal
        .msgBoxConfirm('Please confirm  you want to cancel this return', {
          title: 'Cancel Return',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes, proceed',
          cancelTitle: 'Close',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            const data = { charge_sheet: this.parent, charge_sheet_item: chargeSheetItem }
            chargeSheetCancelReturn(data).then(result => {
              this.load()
              this.msgprint('The returns have been reversed')
            })
          }
        })
    },

    hitReturnApi(items) {
      const payload = {}
      payload.charge_sheet = this.parent
      payload.items = items

      chargeSheetAddItemReturn(payload).then(response => {
        this.$bvToast.toast('Well done. Your adjustments have been accepted back in stock.', {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        this.clearSelected()
        this.load()
      })
    },
  },

}
</script>
