<template>
  <div>
    <b-row>
      <b-col>
        <div style="display: flex; justify-content: flex-end">
          <b-button-group style="margin-top:4px;">
            <b-button
              style="margin-right:2px;"
              size="sm"
              variant="primary"
              @click="addScheme"
            >
              Add
            </b-button>
          </b-button-group>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-bottom: 35px; width: 100%; margin-left : 0px;margin-right : 0px;">
      <b-table
        :filter="filter"
        small
        stacked="sm"
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        :items="insuranceSchemes"
        :empty-text="`No items `"
        :fields="fields"
        :empty-filtered-text="`No items`"
        head-row-variant="primary"
        :show-empty="true"
      >
        <template #cell(action)="row">
          <b-button
            style="margin-right:2px;"
            size="sm"
            variant="primary"
            @click="updateScheme(row.item)"
          >Edit</b-button>
        </template>

        <template #cell(inpatient_limit)="data">
          {{ data.item.inpatient_limit.toFixed(2) | numberWithCommas }}
        </template>

        <template #cell(outpatient_limit)="data">
          {{ data.item.outpatient_limit.toFixed(2) | numberWithCommas }}
        </template>
      </b-table>
    </b-row>

    <b-modal
      v-model="modalVisible"
      title="Add Customer Scheme"
      @ok="submitScheme()"
    >
      <div>

        <b-form-group
          label="Scheme Provider"
          label-for="scheme"
        >
          <doctype-link
            v-model="scheme_name"
            :placeholder="`${scheme_name || 'Search Scheme provider'}`"
            :doctype="'Customer Group'"
            @selected="updateSchemeName"
          />
        </b-form-group>

        <b-form-group
          label="Member Number"
          label-for="member_number"
        >
          <b-form-input
            v-model="member_number"
            type="text"
            required
          />
        </b-form-group>

        <!-- <b-form-group label="Customer" label-for="customer">
                    <doctype-link :placeholder="'Search customer'" v-model="principal_member" :doctype="'Customer'"
                        @selected="updatePrincipalMember" >
                    </doctype-link>

                </b-form-group> -->

        <b-form-group
          label="Relationship to Principal"
          label-for="relationship_to_principal"
        >
          <b-form-select
            v-model="relationship_to_principal"
            :options="relationshipOptions"
            required
          />
        </b-form-group>

        <b-form-group
          label="Inpatient Limit"
          label-for="inpatient_limit"
        >
          <b-form-input
            v-model="inpatient_limit"
            type="number"
            required
          />
        </b-form-group>

        <b-form-group
          label="Outpatient Limit"
          label-for="outpatient_limit"
        >
          <b-form-input
            v-model="outpatient_limit"
            type="number"
            required
          />
        </b-form-group>

      </div>
    </b-modal>

    <b-modal
      v-model="updateModalVisible"
      title="Update Scheme Limits"
      @ok="submitUpdateScheme"
    >
      <div>
        <b-form-group
          label="Customer Group"
          label-for="update_scheme_name"
        >
          <doctype-link
            v-model="update_scheme_name"
            :doctype="'Customer Group'"
            :placeholder="`${update_scheme_name || 'Search Customer Group'}`"
          />
        </b-form-group>

        <b-form-group
          label="Inpatient Limit"
          label-for="update_inpatient_limit"
        >
          <b-form-input
            v-model="update_inpatient_limit"
            type="number"
            required
          />
        </b-form-group>

        <b-form-group
          label="Outpatient Limit"
          label-for="update_outpatient_limit"
        >
          <b-form-input
            v-model="update_outpatient_limit"
            type="number"
            required
          />
        </b-form-group>

        <b-form-group
          label="Outpatient Balance"
          label-for="update_outpatient_balance"
        >
          <b-form-input
            v-model="update_outpatient_balance"
            type="number"
          />
        </b-form-group>

        <b-form-group
          label="Inpatient Balance"
          label-for="update_inpatient_balance"
        >
          <b-form-input
            v-model="update_inpatient_balance"
            type="number"
          />
        </b-form-group>

      </div>
    </b-modal>
  </div>
</template>

<script>
import { getPatientInsuranceSchemes, updatePatientInsuranceSchemeLimits, savePatientScheme } from './service'

export default {
  name: 'Schemes',
  filters: {
    Upper(value) {
      return value.toUpperCase()
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  props: {
    patient_number: {
      type: String,
      default: ' ',
      required: true,
    },
    customerNumber: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {
      insuranceSchemes: [],
      fields: ['scheme', 'member_number', 'relationship_to_principal', 'inpatient_limit', 'outpatient_limit', 'action'],
      modalVisible: false,
      scheme_name: null,
      member_number: null,
      principal_member: null,
      relationship_to_principal: null,
      inpatient_limit: 10000000,
      outpatient_limit: 10000000,
      relationshipOptions: ['Self', 'Spouse', 'Child'],
      updateModalVisible: false,
      update_scheme_name: null,
      update_inpatient_limit: null,
      update_outpatient_limit: null,
      update_outpatient_balance: null,
      update_inpatient_balance: null,
      docname: null,
    }
  },
  watch: {
    patient_number() {
      this.fetchCustomerSchemes()
    },
  },
  created() {
    this.fetchCustomerSchemes()
  },
  methods: {

    updateScheme(schemeItem) {
      // Set the initial values for the update modal
      this.docname = schemeItem.name
      this.update_scheme_name = schemeItem.scheme
      this.update_inpatient_limit = schemeItem.inpatient_limit || 10000000
      this.update_outpatient_limit = schemeItem.outpatient_limit || 10000000
      this.update_outpatient_balance = schemeItem.outpatient_balance || null
      this.update_inpatient_balance = schemeItem.inpatient_balance || null

      // Show the update modal
      this.updateModalVisible = true
    },
    cancelUpdateScheme() {
      this.updateModalVisible = false
    },
    submitUpdateScheme() {
      // Perform your submit logic for updating scheme here
      const apiData = {
        docname: this.docname,
        scheme_name: this.update_scheme_name,
        op_limit: parseFloat(this.update_outpatient_limit) > 0 ? parseFloat(this.update_outpatient_limit) : 0,
        ip_limit: parseFloat(this.update_inpatient_limit) > 0 ? parseFloat(this.update_inpatient_limit) : 0,
        op_balance: parseFloat(this.update_outpatient_balance) > 0 ? parseFloat(this.update_outpatient_balance) : 0,
        ip_balance: parseFloat(this.update_inpatient_balance) > 0 ? parseFloat(this.update_inpatient_balance) : 0,
      }

      updatePatientInsuranceSchemeLimits(apiData)
        .then(response => {
          // Handle the API response
          this.fetchCustomerSchemes() // Replace with your actual method
          this.msgprint('Limits Updated')
        })
        .catch(error => {
          // Handle the API error
          console.error('Error updating scheme limits:', error)
        })
        .finally(() => {
          // Hide the update modal after API call is complete
          this.updateModalVisible = false
        })
    },
    updateSchemex(schemeItem) {
      const parent = this
      frappe.prompt([
        {
          label: 'Customer Group',
          fieldname: 'scheme_name',
          fieldtype: 'Link',
          options: 'Customer Group',
          // reqd: 1
          default: schemeItem.scheme,
        },
        {
          label: 'Inpatient Limit',
          fieldname: 'inpatient_limit',
          fieldtype: 'Currency',
          default: schemeItem.inpatient_limit || 10000000,

        },
        {
          label: 'Outpatient Limit',
          fieldname: 'outpatient_limit',
          fieldtype: 'Currency',
          default: schemeItem.outpatient_limit || 10000000,

        },
        {
          label: 'Outpatient Balance',
          fieldname: 'outpatient_balance',
          fieldtype: 'Currency',
          default: schemeItem.outpatient_balance, // || 10000000,

        },
        {
          label: 'Inpatient Balance',
          fieldname: 'inpatient_balance',
          fieldtype: 'Currency',
          default: schemeItem.inpatient_balance, // || 10000000,

        },
      ], values => {
        updatePatientInsuranceSchemeLimits({
          docname: schemeItem.name,
          scheme_name: values.scheme_name,
          op_limit: parseFloat(values.outpatient_limit) > 0 ? parseFloat(values.outpatient_limit) : 0,
          ip_limit: parseFloat(values.inpatient_limit) > 0 ? parseFloat(values.inpatient_limit) : 0,
          op_balance: parseFloat(values.outpatient_balance) > 0 ? parseFloat(values.outpatient_balance) : 0,
          ip_balance: parseFloat(values.inpatient_balance) > 0 ? parseFloat(values.inpatient_balance) : 0,
        }).then(r => {
          parent.fetchCustomerSchemes()
          this.show_alert({
            message: 'Limits Updated',
            indicator: 'green',
          }, 5)
        })
        // console.log(values.outpatient_limit, values.inpatient_limit);
      })
    },
    addSchemex() {
      const parent = this
      const d = new frappe.ui.Dialog({
        title: 'Add Customer Scheme',
        fields: [{
          label: 'Customer Group',
          fieldname: 'scheme_name',
          fieldtype: 'Link',
          options: 'Customer Group',
          reqd: 1,
        },
        {
          label: 'Member Number',
          fieldname: 'member_number',
          fieldtype: 'Data',
          reqd: 1,
        },
        {
          label: 'Principal Member',
          fieldname: 'principal_member',
          fieldtype: 'Link',
          options: 'Customer',
          reqd: 1,
          default: parent.customerNumber,
        },
        {
          label: 'Relationship to Principal',
          fieldname: 'relationship_to_principal',
          fieldtype: 'Select',
          options: ['Self', 'Spouse', 'Child'],
          reqd: 1,
        },
        {
          label: 'Inpatient Limit',
          fieldname: 'inpatient_limit',
          fieldtype: 'Currency',
          reqd: 1,
          default: 10000000,

        },
        {
          label: 'Outpatient Limit',
          fieldname: 'outpatient_limit',
          fieldtype: 'Currency',
          reqd: 1,
          default: 10000000,
        },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          savePatientScheme({
            patient_name: parent.patient_number,
            scheme_name: values.scheme_name,
            member_number: values.member_number,
            principal_member: values.principal_member,
            relationship_to_principal: values.relationship_to_principal,
            inpatient_limit: values.inpatient_limit,
            outpatient_limit: values.outpatient_limit,
          }).then(r => {
            parent.fetchCustomerSchemes()
            this.show_alert({
              message: 'Patient added to scheme',
              indicator: 'green',
            }, 5)
          })
          d.hide()
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          d.hide()
        },
      })

      d.show()
    },
    fetchCustomerSchemes() {
      getPatientInsuranceSchemes({
        patient_number: this.patient_number,
      }).then(result => {
        if (result) {
          this.insuranceSchemes = result
        }
      })
    },
    addScheme() {
      this.modalVisible = true
    },
    cancelScheme() {
      this.modalVisible = false
    },
    submitScheme() {
      // Perform your submit logic here
      // Access form data using this.scheme_name, this.member_number, etc.

      // Call savePatientScheme API with the form data
      const apiData = {
        patient_name: this.patient_number, // Assuming patient_number is defined in your component
        scheme_name: this.scheme_name,
        member_number: this.member_number,
        principal_member: this.principal_member,
        relationship_to_principal: this.relationship_to_principal,
        inpatient_limit: this.inpatient_limit,
        outpatient_limit: this.outpatient_limit,
      }

      savePatientScheme(apiData)
        .then(response => {
          this.fetchCustomerSchemes()
          // this.msgprint("Patient added to scheme")
          this.modalVisible = false
        })
        .catch(error => {
          // Handle the API error

        })
    },
    updateSchemeName(value) {
      this.scheme_name = value
    },
    updatePrincipalMember(value) {
      this.principal_member = value
    },
  },
}
</script>
