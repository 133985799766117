<template>
  <div>
    <!-- <b-row>
            <b-col>
                <div style="display: flex; justify-content: flex-end">
                    <b-button-group style="margin-right:16px;margin-top:4px;">
                        <b-button style="margin-right:2px;" size="sm" variant="primary" @click="addScheme">Generate Gate Pass </b-button>
                    </b-button-group>
                </div>
            </b-col>
        </b-row>-->

    <b-row style="margin-bottom: 35px; width: 100%; margin-left : 0px;margin-right : 0px;">
      <b-table
        :filter="filter"
        small
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        stacked="sm"
        :items="billCredits"
        :empty-text="`No items `"
        :fields="fields"
        :empty-filtered-text="`No items`"
        head-row-variant="primary"
        :show-empty="true"
      >
        <template #cell(invoice_amount)="data">
          {{ data.item.invoice_amount.toFixed(2) | numberWithCommas }}
        </template>
        <template #cell(recommended_waiver_amount)="data">
          {{ data.item.recommended_waiver_amount.toFixed(2) | numberWithCommas }}
        </template>

      </b-table>
    </b-row>
  </div>
</template>

<script>
import { getPatientBillCredits } from './service'

export default {
  name: 'BillCredit',
  filters: {
    Upper(value) {
      return value.toUpperCase()
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  props: {
    patient_number: {
      type: String,
      default: ' ',
      required: true,
    },
    customerNumber: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {
      billCredits: [],
      fields: ['patient', 'patient_name', 'patient_invoice', 'invoice_amount', 'recommended_waiver_amount', 'creation'],
    }
  },
  watch: {
    patient_number() {
      this.fetchPatientBillCredits()
    },
  },
  created() {
    this.fetchPatientBillCredits()
  },
  methods: {
    fetchPatientBillCredits() {
      getPatientBillCredits({
        patient_number: this.patient_number,
      }).then(result => {
        if (result) {
          this.billCredits = result
        }
      })
    },
  },
}
</script>
