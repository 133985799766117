<template>
  <div>
    <b-button
      class="pull-right"
      variant="success btn-sm"
      @click="GenerateGP()"
    >
      Generate Gate Pass
    </b-button>
  </div>
</template>

<script>
import {
  generateGatePass,
} from './service'

export default {
  name: 'GatePass',
  props: {
    patient: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {
    GenerateGP() {
      generateGatePass({
        patient: this.patient,
      }).then(data => {

      })
    },

  },
}
</script>

<style></style>
