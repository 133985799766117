<template>
  <b-container>
    <b-row> <b-col /> <b-button
      size="sm"
      variant="primary"
      @click="fetchData()"
    >
      Refresh
    </b-button></b-row>
    <b-row>
      <b-skeleton-table
        v-if="isBusy"
        :rows="patients.length || 4"
        :columns="6"
        :table-props="{ bordered: true, striped: true }"
        :loading="loading"
        style="width: 100%"
      />
      <b-table
        v-else
        :items="patients"
        :fields="fields"
        :per-page="per_page"
        :current-page="current_page"
        sticky-header="700px"
        small
        stacked="sm"
        :filter="filter"
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        :empty-text="`No items `"
        :busy.sync="isBusy"
        :empty-filtered-text="`No items`"
        head-row-variant="primary"
        :show-empty="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(id)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(creation)="row">
          <span v-if="row.item.creation">{{ row.item.creation }}</span>
        </template>
        <template #cell(actions)="row">

          <b-button
            size="sm"
            class="mr-2"
            variant="primary"
            @click="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>

        <template #row-details="row">
          <b-card
            style="box-shadow: none !important;"
            class="cardx"
          >
            <CostShareDetailList
              v-if="row.item.name"
              :parent="row.item.name"
              :owner="row.item.owner"
              :patient="patient"
            />
            <b-button
              size="sm"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>
      </b-table>
      <b-pagination
        v-model="current_page"
        :total-rows="total_rows"
        :per-page="per_page"
        class="my-3"
      />

    </b-row>
  </b-container>
</template>

<script>
import CostShareDetailList from './CostShareDetailList.vue'

export default {
  components: { CostShareDetailList },
  props: {
    patient: String,
  },
  data() {
    return {
      sortBy: 'creation',
      sortDesc: true,
      patients: [],
      isBusy: false,
      fields: [
        'id',
        { key: 'name', sortable: true },
        { key: 'pending', sortable: true },
        { key: 'creation', sortable: true },
        { key: 'invoiced', sortable: true },
        { key: 'ward', sortable: true },
        { key: 'warehouse', sortable: true },
        { key: 'workflow_state', sortable: true },
        'actions',
      ],
      current_page: 1,
      per_page: 100,
      total_rows: 0,
    }
  },
  component: {
    CostShareDetailList,
  },
  mounted() {
    // this.fetchData();
  },
  methods: {
    fetchData() {
      const payload = {
        patient: this.patient,
        sort_by: this.sortBy,
        sort_direction: this.sortDesc ? 'desc' : 'asc',
        page: this.current_page,
        per_page: this.per_page,
      }
      this.isBusy = true
      this.api({
        method: 'billing.billing.api.cost_share.actions.list.get_patient_cost_share',
        args: { payload },
      }).then(response => {
        this.isBusy = false
        this.patients = response.map(item => {
          let state_variant = null
          if (item.workflow_state === 'Approved') {
            state_variant == 'success'
          } else if (item.workflow_state === 'Pending Approval') {
            state_variant = 'warning'
          } else {
            state_variant = 'danger'
          }
          item._cellVariants = {
            pending: item.pending > 0 ? 'danger' : 'success',
            workflow_state: state_variant,
            invoiced: item.invoiced ? 'success' : 'danger',
          }
          return item
        })
        this.total_rows = 500
      })
    },
  },
}
</script>
