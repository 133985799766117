import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPatientBillCredits = ({
  patient_number,
}) => api({
  method: 'clinical.api.patient_accounts.get_patient_bill_credits',
  args: {
    patient_number,
  },
})
