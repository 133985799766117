<template>

  <div>
    <b-row style="margin-bottom: 35px; width: 100%; margin-left : 0px;margin-right : 0px;">
      <b-table
        :filter="filter"
        small
        stacked="sm"
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        :items="gatePasses"
        :empty-text="`No items `"
        :fields="fields"
        :empty-filtered-text="`No items`"
        head-row-variant="primary"
        :show-empty="true"
      >

        <template #cell(actions)="row">
          <div class="text-center">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="printGatePAss(row.item)"
            >
              <i
                class="fa fa-print"
                aria-hidden="true"
              /> Print
            </b-button>
          </div>

        </template>
      </b-table>
    </b-row>
  </div>
</template>

<script>
import { getPatientGatePasses, chargeSheetToInvoice } from './service'

export default {
  name: 'GatePassList',
  filters: {
    Upper(value) {
      return value.toUpperCase()
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
  props: {
    patient_number: {
      type: String,
      default: ' ',
      required: true,
    },
    customerNumber: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {
      gatePasses: [],
      fields: ['patient', 'patient_name', 'associated_sales_invoice', 'reference_name', 'remarks', 'creation', 'actions'],
    }
  },
  watch: {
    patient_number() {
      this.fetchPatientGatePasses()
    },
  },
  created() {
    this.fetchPatientGatePasses()
  },
  methods: {
    printGatePAss(gatePass) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
                + 'doctype='}${encodeURIComponent('Gate Pass')
      }&name=${encodeURIComponent(gatePass.name)
      }&format=Gate Pass Print&_lang=en&trigger_print=1`))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    fetchPatientGatePasses() {
      getPatientGatePasses({
        patient_number: this.patient_number,
      }).then(result => {
        if (result) {
          this.gatePasses = result
        }
      })
    },
    chargeSheetToInvoice() {
      chargeSheetToInvoice({
        patient_number: this.patient_number,
      }).then(result => {

      })
    },
  },
}
</script>
