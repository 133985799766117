<template>
  <b-container>
    <b-row>
      <b-col>
        <b-alert
          variant="warning"
          show
        >
          Current scheme balance is 0. Gatepass is unlikely to be successfully generated. Please update this under schemes tab.
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'SchemeBalance',
  props: {
    patient: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {

  },
}
</script>
