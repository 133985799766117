import patientAccountModule from '@/views/erp/state/accounts'
import {
  api,
} from './service'

import ChargeSheet from './components/chargesheet/ChargeSheet.vue'
import ChargeSheetList from './components/chargesheet/ChargeSheetList.vue'
import CostShare from './components/cost-share/CostShare.vue'
import Invoicing from './components/patientbill/PatientBill.vue'
import Receipts from './components/receipts/Receipts.vue'
import Schemes from './components/scheme/Schemes.vue'
import BillCredit from './components/bill-credit/BillCredit.vue'
import GatePassList from './components/gate-pass/GatePassList.vue'
import { GrowBottomNavigation } from "bottom-navigation-vue";

export default {
  name: 'AccountsWorkingArea',

  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      const { patientId } = vm.$root.$route.params
      // if (patientId) {
      //     next();
      // } else next('/accounts-search');
    })
  },
  components: {
    GrowBottomNavigation,
    ChargeSheet,
    ChargeSheetList,
    CostShare,
    Invoicing,
    Receipts,
    Schemes,
    BillCredit,
    GatePassList
    ,
  },
  filters: {
    dateFormat(date) {
      const current_datetime = new Date(date)
      return `${current_datetime.getFullYear()}-${current_datetime.getMonth() + 1}-${current_datetime.getDate()} ${
        current_datetime.getHours()}:${current_datetime.getMinutes()}:${current_datetime.getSeconds()}`
    },
    Upper(value) {
      return value.toUpperCase()
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },

  data() {
    return {
      tab: 0,
      patientId: null,
      patientBalanceObject: {},
      payModalShow: false,
      customer: '',
      patient_no: '',
      response_message: '',
      payment_entry: '',
      charge_sheet: '',
      inpatient_status: '',
      session_user: '',
      selectable_services_general: [],
      selectable_services_private: [],
      selectable_consumables: [],
      batchSize: 1000, // Adjust batch size as needed
      offset: 0,
      components: [
        { title: 'Charge Sheet', component: ChargeSheet },
        { title: 'Charge Sheet List', component: ChargeSheetList },
        { title: 'Cost Share', component: CostShare },
        { title: 'Patient Bill', component: Invoicing },
        { title: 'Payments', component: Receipts },
        { title: 'Schemes', component: Schemes },
        { title: 'Bill Credit', component: BillCredit },
        { title: 'Gate Pass', component: GatePassList },
      ],
      selectedComponent:  { title: 'Charge Sheet', component: ChargeSheet },
      selected: 1,
      options: [
        { id: 1, icon: 'fa-solid fa-home', title: 'Home', color: '#5639af' },
        { id: 2, icon: 'fa-solid fa-magnifying-glass', title: 'Search', color: '#ac4793' },
        { id: 3, icon: 'fa-solid fa-heart', title: 'Likes', color: '#e2a93a' },
        { id: 4, icon: 'fa-solid fa-gear', title: 'Settings', color: '#4493a7' }
      ]
    }
  },
  computed: {
    isSmallScreen() {
      // Adjust the breakpoint as needed
      return  (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ))
    },
    salesOrderTotal() {
      return this.$store.getters['patientAccounts/salesOrderTotal']
    },
    accountSummary() {
      return this.$store.getters['patientAccounts/accountSummary']
    },
    patient() {
      return this.$store.getters['patientAccounts/patient']
    },
  },
  watch: {
    patientId() {
      this.loadPatientData()
    },
  },
  created() {
    this.$store.registerModule('patientAccounts', patientAccountModule)
    this.watch_payments()
  },
  mounted() {
    const patientId = this.$route.params.id;
    this.setTitle("Billing")
    
    if(patientId != null ) {
        this.patientId = patientId;
    }
    this.loadPatientData()
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    this.getPatient()
    // this.get_services_mtrh_batch_pw();
    // this.get_services_mtrh_batch_general();
    this.getPatientAccountSummary()
    // this.getPatientSalesOrderTotals()

    this.get_inpatient_status()
    this.get_session()
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    selectComponent(index) {
      this.selectedComponent = this.components[index];
    },
    loadPatientData(){
      this.getPatient()
      this.getPatientAccountSummary()
    //   this.getPatientSalesOrderrTotals()
      this.get_inpatient_status()
      this.get_session()
    },

    get_session() {
      this.session_user = this.getUserSession().user
      console.log(this.session_user)
    },
    attach_payment() {
      const parent = this
      api({
        method: 'billing.billing.api.payment_integration.process_payment.process_payment_vs_charge_sheet_outpatient',
        args: {
          charge_sheet: this.charge_sheet,
          payment_entry: this.payment_entry,
        },

      }).then(res => {
        console.log(res)
        parent.makeToast('success', res)
      })
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Payment Notification',
        variant,
        solid: true,
      })
    },
    get_inpatient_status() {
      const parent = this
      api({
        method: 'clinical.api.tests.update_items_pw.get_admission_status',
        args: {
          patient: this.patientId,
        },

      }).then(res => {
        console.log('admission status')
        console.log(res)
        this.inpatient_status = res.admission_status
        console.log(this.inpatient_status)
        // parent.makeToast('success', res);
      })
    },
    watch_payments() {
      const parent = this
      console.log(this.patientId)
      let patient_no = this.patientId
      patient_no = `${patient_no}_payments`
      console.log(patient_no)
      // subscribe to channel
      window.socket.on(patient_no, data => {
        // this.show_alert({  message: data.body,indicator: 'green' }, 55);
        // parent.response_message = data.body;
        if (data.title === 'no_charge_sheet') {
          parent.makeToast('info', data.body)
        }

        if (data.title === 'charge_sheet') {
          parent.makeToast('info', data.body)
        }

        console.log(data)
      })
    },
    get_services_mtrh_general() {
      const parent = this

      api({
        method:
                    'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_services_from_charges_all',
        args: {
          is_private_wing: 0,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          console.log(r)
          console.log('items loaded')
          parent.selectable_services_general = r.success
          const array = parent.selectable_services_general
          parent.selectable_services_general = []
          parent.selectable_services_general = parent.sanitizeData(array)
        }
      })
    },
    get_services_mtrh_private() {
      const parent = this

      api({
        method:
                    'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_services_from_charges_all',
        args: {
          is_private_wing: 1,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          console.log(r)
          console.log('items loaded')
          parent.selectable_services_private = r.success
          const array = parent.selectable_services_private
          parent.selectable_services_private = []
          parent.selectable_services_private = parent.sanitizeData(array)

          parent.get_services_mtrh_general()
        }
      })
    },
    get_consumables() {
      const parent = this

      api({
        method:
                    'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_consumables_from_charges_all',
        args: {
          is_private_wing: 1,
        },
        freeze: false,
      }).then(r => {
        if ('success' in r) {
          console.log(r)
          console.log('items loaded')
          parent.selectable_consumables = r.success
          const array = parent.selectable_consumables
          parent.selectable_consumables = []
          parent.selectable_consumables = parent.sanitizeData(array)

          parent.get_services_mtrh_private()
        }
      })
    },
    sanitizeData(response_array) {
      return response_array.map(({
        Item_Name, Name, Item_Price, ...var_rest
      }) => ({
        ...var_rest,
        value: Item_Name,
        label: `${Item_Name} - Price: ${parseInt(Item_Price).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })}`,
        Selling_Price: parseInt(Item_Price).toLocaleString('en-KE', { style: 'currency', currency: 'KES' }),
      }))
    },

    get_services_mtrh_batch_pw() {
      const parent = this
      let { batchSize, offset } = this // Destructure the variables for easy access

      function fetchNextBatch() {
        console.log('getting batch')
        api({
          method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_services_from_charges_lazy',
          args: {
            is_private_wing: 1,
            offset,
            limit: batchSize,
          },
          freeze: false,
        }).then(r => {
          if ('success' in r) {
            console.log('Batch loaded')
            const items = r.success
            console.log(items)
            const sanitized_array = parent.sanitizeData(items)

            // Do something with the fetched items, e.g., store in the component's data
            parent.selectable_services_private.push(...sanitized_array)

            // Check if there are more items to fetch
            if (items.length === batchSize) {
              // Fetch the next batch
              offset += batchSize
              fetchNextBatch()
            } else {
              // All items fetched, do whatever you need to do with the data
              console.log('All items fetched:', parent.selectable_services_private)
              // parent.set_sevices(); // You may call a function to process the fetched data here
            }
          }
        })
      }

      // Start fetching the first batch
      fetchNextBatch()
    },

    get_services_mtrh_batch_general() {
      const parent = this
      let { batchSize, offset } = this // Destructure the variables for easy access

      function fetchNextBatch() {
        console.log('getting batch')
        api({
          method: 'billing.billing.api.charge_sheet.actions.create_charge_sheet.get_services_from_charges_lazy',
          args: {
            is_private_wing: 0,
            offset,
            limit: batchSize,
          },
          freeze: false,
        }).then(r => {
          if ('success' in r) {
            console.log('Batch loaded')
            const items = r.success
            console.log(items)
            const sanitized_array = parent.sanitizeData(items)

            // Do something with the fetched items, e.g., store in the component's data
            parent.selectable_services_general.push(...sanitized_array)

            // Check if there are more items to fetch
            if (items.length === batchSize) {
              // Fetch the next batch
              offset += batchSize
              fetchNextBatch()
            } else {
              // All items fetched, do whatever you need to do with the data
              console.log('All items fetched:', parent.selectable_services_general)
              // parent.set_sevices(); // You may call a function to process the fetched data here
            }
          }
        })
      }

      // Start fetching the first batch
      fetchNextBatch()
    },

    newPaymentEntry() {
      this.getPatientAccountSummary()
      this.getPatientSalesOrderTotals()
    },
    getPatientAccountSummary() {
      this.$store.dispatch('patientAccounts/fetchPatientAccountSummary', this.patientId)
    },
    getPatientSalesOrderTotals() {
      this.$store.dispatch('patientAccounts/fetchUnbilledSalesOrderTotals', this.patientId)
    },
    getPatient() {
      this.$store.dispatch('patientAccounts/fetchPatient', this.patientId)
    },
  },
}