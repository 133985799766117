<template>
  <b-container>
    <!-- <scheme-balance/> -->
    <!-- v-if="!chargeSheetCount && !pendingCostShareCount" -->
    <b-row v-if="pendingCostShareCount || unBilledChargeSheetsCount" style="margin-top:10px;">

      <b-col>

        <b-alert v-if="pendingCostShareCount" variant="danger" show> WARNING: You have {{ pendingCostShareCount }}
          UNCHARGED cost shares(s) Click
          Charge Cost Share</b-alert>
        <b-alert v-if="unBilledChargeSheetsCount" variant="warning" show> WARNING: You have {{ unBilledChargeSheetsCount
        }} charge sheet(s) unattached to a Patient Bill. Click
          Attach To Bill</b-alert>

      </b-col>
    </b-row>

    <b-row style="width: 100% !important;" align-h="end">

      <b-button-group size="sm" style="margin-right:0px;margin-top:4px;">

        <b-button style="margin-right:2px;" variant="primary" size="sm" @click="fetchPatientBills()"> <i
            class="fa-solid fa-arrows-rotate" />
        </b-button>
      </b-button-group>

      <b-button-group v-if="!chargeSheetCount && !pendingCostShareCount" size="sm"
        style="margin-right:16px;margin-top:4px;">
        <gate-pass :patient="patient" />
      </b-button-group>

      <b-button-group size="sm" style="margin-right:4px;margin-top:4px;">
        <b-button style="margin-right:2px;" variant="outline-secondary" size="sm" :disabled="chargingCostShare"
          @click="chargeCostShare()"> {{ chargingCostShare ? 'Charging Cost Share ...' : 'Charge Cost Share' }}
        </b-button>
      </b-button-group>

      <b-button-group size="sm" style="margin-right:16px;margin-top:4px;">
        {{ }}
        <b-button style="margin-right:2px;" variant="outline-secondary" size="sm" :disabled="attachingToBill"
          @click="attachToPatientBill()"> {{ attachingToBill ? 'Attaching to bill ...' : 'Attach To Bill' }}
        </b-button>
      </b-button-group>


    </b-row>
    <div class="container mt-1">
      <b-table :items="patientBills" small stacked="sm" responsive="sm" bordered striped :empty-text="`No items`"
        :fields="fields" :empty-filtered-text="`No items`" head-row-variant="primary" :show-empty="true">
        <template #cell(index)="data">
          {{ (currentPage - 1) * pageLength + data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link @click="openPatientBill(data.item.name)">
            {{ data.value }}
          </b-link>
        </template>
        <template #cell(actions)="data" class="w-100">
          <b-row align-h="between" class="w-100" align-v="between">

            <b-button size="sm"  style="margin-bottom: 2px; min-width: 72px;" @click="data.toggleDetails"> {{ data.detailsShowing ? 'Less' :
            'More' }} </b-button>
          <b-button  style="margin-bottom: 2px; min-width: 72px;" class="" size="sm" :variant="data.item.status === 'Draft' ? 'danger' : 'success'"
            @click="togglePatientBillState(data.item.name, data.item.status)">
            {{ data.item.status === 'Draft' ? 'Close' : 'Reopen' }}
          </b-button>
          <b-button style="margin-bottom: 2px;  min-width: 72px;" size="sm" variant="primary" @click="openPatientBill(data.item.name)">
            View
          </b-button>
          <b-dropdown size="sm" text="Print" variant="primary">
            <b-dropdown-item variant="primary" @click="printSummaryFormat(data.item.name)">Summary Format
            </b-dropdown-item>
            <b-dropdown-item variant="primary" @click="printSummaryFormatInpatient(data.item.name)">Summary Format
              Inpatient</b-dropdown-item>
            <b-dropdown-item variant="primary" @click="printInterimFormat(data.item.name)">Interim Format
            </b-dropdown-item>
            <b-dropdown-item variant="primary" @click="printInterimInpatient(data.item.name)">Interim Inpatient
              Format</b-dropdown-item>
            <b-dropdown-item variant="primary" @click="printGrouped(data.item.name)">
              Grouped Format
            </b-dropdown-item>
            <b-dropdown-item variant="primary" @click="printGroupedInpatient(data.item.name)">Grouped Format Inpatient
            </b-dropdown-item>
          </b-dropdown>
          </b-row>
         
        </template>
        <template #cell(customer)="data">
          {{ data.item.customer_name }}
        </template>
        <template #cell(status)="data" :class="getStatusClass(data.item.status)">
          {{ data.value }}
        </template>
        <template #cell(creation)="data">
          {{ data.value }}
        </template>
        <template #cell(total)="data">
          {{ data.value.toFixed(2) }}
        </template>

        <template #row-details="row">
          <b-card style="box-shadow: none !important;" class="cardx">

            <b-button class="ml-1" size="sm" variant="outline-primary" @click="row.toggleDetails">
              <i class="fa-solid fa-chevron-up" /></b-button>
            <PatientBillDetail :patient="patient" :name="row.item.name" />
          </b-card>
        </template>
      </b-table>

      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="pageLength" />
    </div>
  </b-container>
</template>

<script>
import GatePass from '../gate-pass/GatePass.vue'
import PatientBillDetail from './PatientBillDetail.vue'
import SchemeBalance from '../scheme/SchemeBalance.vue'
import {
  chargePatientCostShare, attachChargeSheetToBill,
} from './service'

export default {
  components: {
    GatePass, PatientBillDetail, SchemeBalance,
  },
  props: {
    patient: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      patientBills: [],
      fields: [{ key: 'index', label: '' }, 'name', 'customer', 'status', 'posting_date', 'total', { key: 'actions', label: '' }],
      currentPage: 1,
      pageLength: 30,
      totalRows: 0,
      attachingToBill: false,
      chargingCostShare: false,
      pendingCostShareCount: 0,
      unBilledChargeSheetsCount: 0,
      costSharesChecked: false,
      patientBillChecked: false,
    }
  },
  watch: {
    patient() {
      this.fetchInfo()
    },
  },
  created() {
    this.fetchInfo()
  },
  mounted() {
    this.fetchPatientBills()
  },
  methods: {

    printGroupedInpatient(docname) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Bill')
        }&name=${encodeURIComponent(docname)
        }&format=${encodeURIComponent('PB-Item Grouped Format-Inpatient')
        }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))

      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    printGrouped(docname) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Bill')
        }&name=${encodeURIComponent(docname)
        }&format=${encodeURIComponent('PB-Item Grouped Format')
        }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))

      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    printInterimInpatient(docname) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Bill')
        }&name=${encodeURIComponent(docname)
        }&format=${encodeURIComponent('Interim Bill Inpatient')
        }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))

      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    printInterimFormat(docname) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Bill')
        }&name=${encodeURIComponent(docname)
        }&format=${encodeURIComponent('Interim Bill')
        }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))

      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    printSummaryFormatInpatient(docname) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Bill')
        }&name=${encodeURIComponent(docname)
        }&format=${encodeURIComponent('PB-Summary Inpatient')
        }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))

      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    printSummaryFormat(docname) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
        + 'doctype='}${encodeURIComponent('Patient Bill')
        }&name=${encodeURIComponent(docname)
        }&format=${encodeURIComponent('PB-Summary Format')
        }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))

      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    fetchInfo() {
      this.checkCostShares()
      this.checkUnBilledChargeSheets()
      console.log('Refetched...')
    },
    checkUnBilledChargeSheets() {
      const { patient } = this
      const url = 'billing.billing.api.scripts.invoices_fix.check_unbilled_charge_sheets'
      this.api(url, {
        patient,

      }).then(r => {
        this.unBilledChargeSheetsCount = r.message
        this.patientBillChecked = true
      })
    },
    checkCostShares() {
      const { patient } = this
      const url = 'billing.billing.api.cost_share.actions.sales_invoice_item.get_patient_cost_share_count'
      this.api(url, {
        patient,

      }).then(r => {
        this.pendingCostShareCount = r.message
        this.costSharesChecked = true
      })
    },
    attachToPatientBill() {
      const context = this
      this.$bvModal.msgBoxConfirm('Proceed?')
        .then(value => {
          if (value) {
            context.attachingToBill = true
            context.$bvToast.toast(' Attaching charge sheet to bill has started', {
              title: 'Attachment Started!',
              variant: 'success',
              autoHideDelay: 3000,
              append: true,
              solid: true,
            })
            attachChargeSheetToBill(context.patient).then(result => {
              context.$bvToast.toast(' Patient Bill attachment process has completed successfully', {
                title: 'Success, Attachment Completed!',
                variant: 'success',
                autoHideDelay: 1000,
                append: true,
                solid: true,
              })
              frappe.utils.play_sound('submit')
              context.attachingToBill = false
              context.fetchInfo()
            })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    chargeCostShare() {
      const context = this
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (value) {
            context.chargingCostShare = true
            context.$bvToast.toast(' Cost share charging has started', {
              title: 'Charging Started!',
              variant: 'success',
              autoHideDelay: 3000,
              append: true,
              solid: true,
            })
            chargePatientCostShare(context.patient).then(result => {
              context.$bvToast.toast(' Process has completed successfully', {
                title: 'Success, Charged!',
                variant: 'success',
                autoHideDelay: 1000,
                append: true,
                solid: true,
              })
              frappe.utils.play_sound('submit')
              context.chargingCostShare = false
              context.fetchInfo()
            })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    fetchPatientBills() {
      this.api('billing.billing.api.patient_bill.get_bill.get_patient_bills_by_patient', {
        patient: this.patient,
        page: this.currentPage,
        page_length: this.pageLength,
      }).then(response => {
        this.patientBills = response.message.patient_bills
        this.totalRows = response.message.total_records
      }).catch(error => {
        console.error('Error fetching patient bills:', error)
      })
    },

    openPatientBill(patientBillName) {
      const url = `/app/patient-bill/${patientBillName}`
      window.open(url, '_blank')
    },
    togglePatientBillState(patientBillName, currentState) {
      const newState = currentState === 'Draft' ? 'Closed' : 'Draft'

      if (newState == 'Closed') {
        this.api({
          method: 'billing.billing.api.patient_bill.clean.delete_patient_bills',
          args: {
            patient: this.patient,
          },
        }).then(r => {
          this.show_alert('Cleared Zero Bills')

          this.docSetValue('Patient Bill', patientBillName, 'status', newState)
            .then(() => {
              this.fetchPatientBills()
            })
            .catch(error => {
              console.error('Error toggling patient bill state:', error)
            })
        })
      } else {
        this.api({
          method: 'billing.billing.api.patient_bill.validations.check_if_bill_has_gate_pass',
          args: {
            bill: patientBillName,
          },
          callback: r => {

          },
        }).then(r => {
          if (r.message === true) {
            this.show_alert('You are not allowed to reopen a bill which already has a gate pass!')
          } else {
            this.docSetValue('Patient Bill', patientBillName, 'status', newState)
              .then(() => {
                this.fetchPatientBills()
              })
              .catch(error => {
                console.error('Error toggling patient bill state:', error)
              })
          }
        })
      }
    },
    getStatusClass(status) {
      return status === 'Draft' ? 'text-danger' : 'text-success'
    },
  },
}
</script>

<style>
/* You can add custom styles here */
</style>
