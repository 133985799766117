import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))
export const getPatientInsuranceSchemes = ({
  patient_number,
}) => api({
  method: 'clinical.api.patient_accounts.get_patient_insurance_scheme',
  args: {
    patient_number,
  },
})

export const updatePatientInsuranceSchemeLimits = ({
  docname,
  scheme_name,
  op_limit = 0,
  ip_limit = 0,
  op_balance = 0,
  ip_balance = 0,
}) => api({
  method: 'billing.billing.api.accounts.patients.update_patient_scheme',
  args: {
    docname,
    scheme_name,
    op_limit,
    ip_limit,
    op_balance,
    ip_balance,
  },
})

export const savePatientScheme = ({
  patient_name,
  scheme_name,
  member_number,
  principal_member,
  relationship_to_principal,
  inpatient_limit,
  outpatient_limit,
}) => api({
  method: 'billing.billing.api.accounts.patients.create_patient_scheme',
  args: {
    patient_name,
    scheme_name,
    member_number,
    principal_member,
    relationship_to_principal,
    inpatient_limit,
    outpatient_limit,
  },
})
