<template>
  <b-container class="mt-1">
    <!-- Button to open the Split Bill modal -->
    <b-row
      align-h="between"
      class="w-100"
    >
      <div
        class="w-40"
        style="width: 50% !important;"
      >  <doctype-link
        class="w-100"
        :doctype="'Warehouse'"
        :label="'Warehouse'"
        :fieldname="'warehouse'"
        @selected="filteredWarehouse"
      /></div>
      <div>
        <b-button
          v-b-modal.modalSplitBill
          size="sm"
          variant="primary"
          class="mr-1"
          @click="selectedDoctypeReference = null"
        >
          {{ this.loading ? "Migrating ..." : " Migrate Bill" }}
        </b-button>
        <b-button
          v-b-modal.modalSplitBill
          variant="danger"
          size="sm"
          class=""
          @click="clearFilters()"
        >
          Clear filters
        </b-button>
      </div>
    </b-row>

    <!-- Split Bill modal with date picker -->
    <b-modal
      id="modalSplitBill"
      title="SPLIT AND MIGRATE"
      @ok="onSplitBillDateSelected"
    >
      <b-row
        style="width: 100%;"
        class="my-2 mx-2"
      >
        <h5>From Date(**If any**)</h5>
      </b-row>
      <b-form-datepicker
        v-model="selectedDateForSplitBill"
        :max="new Date()"
      />
      <b-row
        style="width: 100%;"
        class="my-2 mx-2"
      >
        <h5>Target Bill(**If any**)</h5>
      </b-row>
      <doctype-link
        :doctype="'Patient Bill'"
        :label="'Target Bill'"
        :fieldname="'patientbill'"
        :filters="linkFilters"
        style="width: 100%"
        @data="processData"
      />

      <b-row
        style="width: 100%;"
        class="my-2 mx-2"
      >
        <h5>Filter Warehouse(**If any**)</h5>
      </b-row>
      <doctype-link
        :doctype="'Warehouse'"
        :label="'Warehouse'"
        :fieldname="'warehouse'"
        style="width: 100%"
        @data="selectWarehouse"
      />
      <div v-if="this.selectedRows.length > 0">

        <h4>Selected Charge Sheet:</h4>
        <b-form-checkbox
          id="checkbox-1"
          v-model="createNew"
        >
          <span style="color: red;"> CREATE NEW PATIENT BILL</span>
        </b-form-checkbox>
        <br>
        <b>{{ JSON.stringify(this.selectedRows.map(item => item.charge_sheet_name)) }}</b>

      </div>

    </b-modal>

    <!-- Button to open the Merge Bill modal -->
    <b-button
      v-if="false"
      v-b-modal.modalMergeBill
      variant="primary"
      size="sm"
    >
      {{ this.loading ? "Migrating ..." : " Migrate Bill" }}
    </b-button>

    <!-- Merge Bill modal with date picker -->
    <b-modal
      id="modalMergeBill"
      ref="my-modal"
      title="Select Date for Merge Bill"
      @ok="onMergeBillDateSelected"
    >
      <b-form-datepicker
        v-model="selectedDateForMergeBill"
        :max="new Date()"
      />
    </b-modal>

    <b-table
      ref="table"
      :items="chargeSheetItems"
      :fields="fields"
      stacked="sm"
      responsive
      selectable
      select-mode="multi"
      :selected.sync="selectedRows"
      detail-field="charge_sheet_name"
      show-detail-on-click
      head-variant="primary"
      variant="primary"
      small
      bordered
      striped
      :show-empty="true"
      :empty-text="showEmptyMessage ? 'This table is empty' : ''"
      @row-selected="onRowSelected"
    >
      <template #row-details="row">
        <b-card>
          <b-card-text>
            <strong>Charge Sheet Name:</strong> {{ row }}
          </b-card-text>
        </b-card>
      </template>

      <template #cell(id)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(amount)="row">
        {{ formatAmount(row.value) }}
      </template>

      <template #cell(item_name)="row">
        {{ row.value }}
      </template>

      <template #cell(modified)="row">
        {{ formatModified(row.value) }}
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalPages * pageLength"
      :per-page="pageLength"
      align="center"
    />
  </b-container>
</template>

<script>
export default {
  props: {
    name: String,
    patient: String,
  },

  data() {
    return {
      currentPage: 1,
      pageLength: 20,
      totalPages: 1,
      filteringWarehouse: null,
      chargeSheetItems: [],
      fields: [
        'id',
        { key: 'item_name', label: 'Item Name' },
        { key: 'qty', label: 'Quantity' },
        { key: 'rate', label: 'Rate' },
        { key: 'amount', label: 'Amount' },
        { key: 'warehouse', label: 'Warehouse' },
        { key: 'charge_sheet_name', label: 'Charge Sheet' },
      ],
      selectedRows: [],
      createNew: true,
      selectedDateForSplitBill: null,
      selectedDateForMergeBill: null,
      selectedDoctypeReference: null,
      filterWarehouse: null,
      linkFilters: {
        patient: this.patient,
        status: 'Draft',
        name: ['NOT IN', this.name],
      },
      loading: false,
      target_bill: null,

    }
  },
  computed: {
    showEmptyMessage() {
      return this.chargeSheetItems.length === 0
    },
  },
  watch: {
    currentPage(newPage, old) {
      if (newPage != old) {
        this.fetchData(newPage)
      }
    },
  },
  mounted() {
    this.fetchData(this.currentPage)
  },
  methods: {
    showModal() {
      this.selectedDoctypeReference = null
      this.$refs['my-modal'].show()
    },
    migrateChargeSheets(target) {
      if (this.createNew) {
        target = null
      }
      this.api('billing.billing.api.patient_bill.split.update_charge_sheets_sql', {
        charge_sheet_names: JSON.stringify(this.selectedRows.map(item => item.charge_sheet_name)),
        source_bill: this.name,
        target_bill: target,
      }).then(response => {
        cur_frm.reload_doc()
      })
    },
    processData(data) {
      this.selectedDoctypeReference = data
    },
    selectWarehouse(data) {
      this.filterWarehouse = data
    },
    filteredWarehouse(data) {
      this.filteringWarehouse = data
      this.filteredWarehouse = null
      this.fetchData(1)
    },
    clearFilters() {
      this.filteredWarehouse = null
      this.fetchData(1)
    },

    fetchData(page) {
      const args = {
        patient_bill_number: this.name, // Set patient_bill_number to this.name
        page,
        page_length: this.pageLength,
      }

      this.api('billing.billing.api.patient_bill.actions.get_charge_sheet_items_by_patient_bill', args)
        .then(response => {
          const data = response.message
          this.currentPage = data.page
          this.totalPages = data.total_pages
          this.chargeSheetItems = data.charge_sheet_items.map(item => ({
            ...item,
            creation: this.formatModified(item.creation),
          }))
        })
    },
    formatAmount(amount) {
      return amount.toFixed(2)
    },
    onRowSelected(selectedItems) {
      this.selectedRows = selectedItems
    },
    formatModified(datetimeString) {
      const dateObj = new Date(datetimeString)
      const formattedDate = dateObj.toLocaleDateString()
      const formattedTime = dateObj.toLocaleTimeString()
      return `${formattedDate} ${formattedTime}`
    },
    onSplitBillDateSelected() {
      if (this.selectedRows.length == 0) {
        const args = {
          bill_number: this.name, // Set patient_bill_number to this.name
          target: this.selectedDoctypeReference,
          warehouse: this.filterWarehouse,
          selected_date: this.selectedDateForSplitBill,
        }
        this.loading = true
        this.api('billing.billing.api.patient_bill.split.create_new_patient_bill_from_existing', args)
          .then(response => {
            this.show_alert('Operation completed')
            cur_frm.reload_doc()
            this.loading = false
          })
      } else {
        this.migrateChargeSheets(this.selectedDoctypeReference)
      }
    },
    onMergeBillDateSelected() {
      // Make the Frappe call for Merge Bill with the selected date and patient bill number
      if (this.selectedDateForMergeBill) {
        const args = {
          patient_bill_number: this.name, // Set patient_bill_number to this.name
          selected_date: this.selectedDateForMergeBill,
        }

        this.api('your_api_method_for_merge_bill_date_selection', args)
          .then(response => {
            // Handle the response from the server if needed
            console.log('Merge Bill Date selection response:', response)
          })
          .catch(error => {
            console.error('Error in Merge Bill date selection:', error)
          })
      }
    },
  },
}
</script>

<style>
/* Custom styles */

/* Optional: Adjust the margin and padding of the buttons and the table */
.b-button {
  margin-right: 5px;
  margin-bottom: 5px;
}

.b-table {
  margin-top: 10px;
}

/* Optional: Center the pagination */
.b-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>
